import request from '@/util/request'

export default {
    //提交精英申请
    appElite(query) {
        return request({
            url: "/api/user/appElite",
            method: "post",
            data: { ...query }
        })
    },
    // 获取申请精英状态
    getElite(){
        return request({
            url:"/api/user/getElite",
            method:"post"
        })
    }
}